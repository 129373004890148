import React, { Component } from 'react';

class Skills extends Component {
    render() {
        if(this.props.data){
            var golang = "images/go.svg"
            var python = "images/python.svg"
            var kube = "images/kubernetes.svg"
            var kafka = "images/kafka-icon.svg"
            var node = "images/nodejs.svg"
            var aws = "images/aws.svg"
            var couch = "images/couchbase.svg"
            var docker = "images/docker-icon.svg"
            var react = "images/react.svg"
            var github = "images/github-icon.svg"
            var django = "images/django.svg"
            var tensorflow = "images/tensorflow.svg"
        }
        const logos = [golang, python, kube, kafka, node, aws, react, tensorflow, django, github, couch, docker];
        const Skill = ({ skill }) => (
            <img
            className="skill-logo skill-transition"
            src={skill}
            alt="">
            </img>
        )
    return ( 
        <section id="skills" data-aos="fade-right">
            <h2>Skills</h2>
            <div className="skills-flex-container">
                {logos.map((value, index) => {
                    return <Skill key={index} skill={value}/>
                })}
            </div>

        </section> 

    );
    }
}



export default Skills;