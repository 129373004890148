import React, { Component } from 'react';

class About extends Component {
  render() {

    if(this.props.data){
      var pic= "images/coding.svg";
      var bio = this.props.data.bio;
    }

    return (
      <section id="about">
      <div className="row">
         <div className="three columns">
            <img className="profile-pic"  src={pic} alt="Ken Obie Profile Pic" />
         </div>
         <div className="nine columns main-col">
            <h2>Hi,</h2>

            <p>{bio}</p>
         
         </div>
      </div>

   </section>
    );
  }
}

export default About;
