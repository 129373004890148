import React, { Component } from 'react';
import Typical from 'react-typical';

class Header extends Component {
  render() {
      

    if(this.props.data){
      var profilepic="images/profile.png"
      var networks= this.props.data.social.map(function(network){
        return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
      })
    }

    return (
      <header id="home">

      <nav id="nav-wrap">

         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
            <li><a className="smoothscroll" href="#about">Bio</a></li>
	         <li><a className="smoothscroll" href="#resume">Experience</a></li>
            <li><a className="smoothscroll" href="#portfolio">Portfolio</a></li>
            <li><a className="smoothscroll" href="#apps">Apps</a>
               {/* <ul>
                  <li><a href="/apps/pathfinder">Path Finder</a></li>
               </ul> */}
            </li>
            {/* <li><a className="smoothscroll" href="#Blog">Blog</a></li> */}
            <li><a className="smoothscroll" href="#contact">Contact</a></li>
         </ul>

      </nav>

      <div className="row banner">
         <div className="banner-text">
         <img className="pic" src={profilepic} alt="profile-pic"></img>
             {/* <Typing>
                 <h1 className="responsive-headling">Ken O'Brien<span class="blinking-cursor">|</span></h1>
             </Typing>
            <h3>Software Engineer</h3> */}
            <h1>Ken O'Brien</h1>
            <p>I'm a {''}
               <Typical
                  loop={Infinity}
                  wrapper='b'
                  steps={[
                     'developer 🧑🏼‍💻',
                     1200,
                     'tech enthusiast 📲 ',
                     1200,
                     'machine learning engineer 🧑🏼‍🔧',
                     1200,
                     'blockchain enthusiast 📈',
                     1200,
                     'film guru 🎬',
                     1200
                  ]}
                  />
               </p>
            
            <ul className="social">
               {networks}
            </ul>
            {/* <img className="pic" src={profilepic}></img> */}
            {/* <p className="intro">Welcome to my digital portfolio.<br></br>I started out making the content for the entertainment industry.<br></br> Now I'm a Software Engineer helping create the applications that stream it.</p> */}
         </div>
      </div>
      <p className="scrolldown">
         <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
      </p>

   </header>
    );
  }
}

export default Header;
