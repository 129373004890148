import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './pathFinder/visual.css'
import App from './App';
import PathfindingVisualizer from './pathFinder/visual';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter, Route, Switch } from "react-router-dom";


// ReactDOM.render(<App />, document.getElementById('root'));

const rootElement = document.getElementById("root");
ReactDOM.render(
    <BrowserRouter>
    <Switch>
        <Route exact path="/" component={App}/>
        <Route path="/apps/pathfinder" component={PathfindingVisualizer}/>
    </Switch>
    </BrowserRouter>,
    rootElement
)

registerServiceWorker();
