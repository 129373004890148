import React, { Component } from 'react';
import ReactGA from 'react-ga';
import $ from 'jquery';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Resume from './Components/Resume';
import Contact from './Components/Contact';
import Portfolio from './Components/Portfolio';
import Skills from './Components/Skills'; 
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PathfindingVisualizer from './pathFinder/visual';
import Apps from './Components/Apps';

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      foo: 'bar',
      resumeData: {}
    };

    ReactGA.initialize('G-MG8HDXLLKK');
    ReactGA.pageview(window.location.pathname);

  }

  getResumeData(){
    $.ajax({
      url:'/resumeData.json',
      dataType:'json',
      cache: false,
      success: function(data){
        this.setState({resumeData: data});
      }.bind(this),
      error: function(xhr, status, err){
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount(){
    this.getResumeData();
  }

  render() {
    return (
      <div className="App">
        <Router>
          <Header data={this.state.resumeData.main}/>
          <About data={this.state.resumeData.main}/>
          <Skills data={this.state.resumeData.main}/>
          <Resume data={this.state.resumeData.resume}/>
          <Apps data={this.state.resumeData.apps}/>
          <Portfolio data={this.state.resumeData.portfolio}/>
          <Contact data={this.state.resumeData.main}/>
          <Footer data={this.state.resumeData.main}/>
        </Router>

        <Router>
          <Switch>
            <Route path="/apps/pathfinder" exact component={() => <PathfindingVisualizer/>} />
          </Switch>
        </Router>
        
        
      
      </div>
    );
  }
}

export default App;
