import React, { Component } from 'react';

class Apps extends Component {
  render() {

    if(this.props.data){
      var apps = this.props.data.apps.map(function(apps){
        var appImage = 'images/apps/'+apps.image;
        return <div key={apps.name} className="columns portfolio-item">
           <div className="item-wrap">
            <a href={apps.url} title={apps.name}>
               <img alt={apps.name} src={appImage} />
               <div className="overlay">
                  <div className="portfolio-item-meta">
                 <h5>{apps.name}</h5>
                     <p>{apps.category}</p>
                  </div>
                </div>
              <div className="link-icon"><i className="fa fa-link"></i></div>
            </a>
          </div>
        </div>
      })
    }

    return (
      <section id="apps">

      <div className="row">

         <div className="twelve columns collapsed">

            <h1>My Applications</h1>

            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                {apps}
            </div>
          </div>
      </div>
   </section>
    );
  }
}

export default Apps;

